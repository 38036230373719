import React from 'react'
import PropTypes from 'prop-types'
import { graphql, Link } from 'gatsby'
import Layout from '../components/Layout'
import Features from '../components/Features'
import { Wrapper } from '../components/Wrapper'

export const HOABoardPageTemplate = ({
    image,
    title,
    heading,
    description,
    directors,
    administrators
}) => (
    <Wrapper>
        <div
            className="full-width-image-container margin-top-0"
            style={{
                backgroundImage: `url(${
                    image.childImageSharp
                        ? image.childImageSharp.fluid.src
                        : image
                })`,
                backgroundAttachment: `fixed`,
            }}
        >
            <h2
                className="has-text-weight-bold is-size-1"
                style={{
                    boxShadow: '0.5rem 0 0 #00640a, -0.5rem 0 0 #00640a',
                    backgroundColor: '#00640a',
                    color: 'white',
                    padding: '0.25rem',
                }}
            >
                {title}
            </h2>
        </div>
        <div className="columns">
            <div className="column is-7">
                <h3 className="has-text-weight-semibold is-size-2">
                    {heading}
                </h3>
                <p>{description}</p>
            </div>
        </div>
        <Features gridItems={directors.blurbs} />
        <div className="columns">
            <div className="column is-7">
                <h3 className="has-text-weight-semibold is-size-2">
                    Administrators
                </h3>
            </div>
        </div>
        <Features gridItems={administrators.blurbs}/>
        <p>
            All Directors and Administrators can be reached via the <Link to="/contact">contact</Link> page or directly
            at <a href="mailto:woodlandgreensoh@gmail.com">woodlandgreensoh@gmail.com</a>
        </p>
    </Wrapper>
)

HOABoardPageTemplate.propTypes = {
    image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    title: PropTypes.string,
    heading: PropTypes.string,
    description: PropTypes.string,
    directors: PropTypes.shape({
        blurbs: PropTypes.array,
    }),
    administrators: PropTypes.shape({
        blurbs: PropTypes.array,
    }),
}

const HOABoardPage = ({ data }) => {
    const { frontmatter } = data.markdownRemark

    return (
        <Layout>
            <HOABoardPageTemplate
                image={frontmatter.image}
                title={frontmatter.title}
                heading={frontmatter.heading}
                description={frontmatter.description}
                directors={frontmatter.directors}
                administrators={frontmatter.administrators}
            />
        </Layout>
    )
}

HOABoardPage.propTypes = {
    data: PropTypes.shape({
        markdownRemark: PropTypes.shape({
            frontmatter: PropTypes.object,
        }),
    }),
}

export default HOABoardPage

export const hoaboardPageQuery = graphql`
  query HOABoardPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        title
        image {
          childImageSharp {
            fluid(maxWidth: 2048, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        heading
        description
        directors {
          blurbs {
            text
          }
        }
        administrators {
          blurbs {
            text
          }
        }
      }
    }
  }
`
